import React from "react";

import { Autocomplete, FormControl, TextField } from "@mui/material";
import { Theme as MuiTheme } from "@mui/material/styles";
import { SxProps } from "@mui/system";
import { Widget, WidgetProps } from "@rjsf/utils";

import { useCustomerTheme } from "../../../theme-utils";

const CustomSelectFontWidget: Widget = function (props: WidgetProps) {
  const { theme, customFonts } = useCustomerTheme();

  const formControlStyle: SxProps<MuiTheme> = {
    marginTop: 1,
    marginBottom: 1,
  };

  const labelRender = props.required && props.label ? `${props.label}*` : props.label;

  const fontFamilies: string[] = theme.typography.fontFamily
    ?.replaceAll('"', "")
    .replaceAll(`'`, "")
    .split(", ") as string[];

  const autocompleteOptions = fontFamilies
    ? fontFamilies.map((font) => ({
        id: font,
        label: font,
      }))
    : [];

  /**
   * Function to get the fonts that are currently applied on the theme
   * Thanks to that we can pre-fill the select with the right value
   * @param tagName
   */
  const getCurrentAppliedFontByTag = (tagName: string) => {
    /**
     *  We get the fonts by the tagName (ex: h1), then we check if the value is an array or a string
     *  The value can be for example "Arial", in that case we can return the value without changes
     *  But sometimes, when the fonts have not been configures, we have '"Roboto", "Helvetica", "Arial", sans-serif',
     *  so we must return the first font of the list
     */

    if (tagName === "global") {
      if (theme.typography.body1.fontFamily?.includes(", ")) {
        const splitFonts = theme.typography.body1?.fontFamily?.split(", ");
        if (splitFonts && splitFonts.length !== 0) {
          if (splitFonts[0][0] === '"') {
            return splitFonts[0].slice(1, -1);
          }
          return splitFonts[0];
        }
      }
      return theme.typography.body1?.fontFamily;
    } else {
      if (theme.typography[tagName]?.fontFamily?.includes(", ")) {
        const splitFonts = theme.typography[tagName]?.fontFamily?.split(", ");
        if (splitFonts && splitFonts.length !== 0) {
          if (splitFonts[0][0] === '"') {
            return splitFonts[0].slice(1, -1);
          }
          return splitFonts[0];
        }
      }
      return theme.typography[tagName]?.fontFamily;
    }
  };

  const val =
    props.value && fontFamilies.find((f) => f === props.value) !== undefined
      ? props.value
      : getCurrentAppliedFontByTag(props.name);

  return (
    <FormControl sx={formControlStyle}>
      <Autocomplete
        options={
          customFonts && customFonts?.length !== 0
            ? customFonts.map((c) => {
                return { id: c.fontFamily, label: c.fontFamily };
              })
            : autocompleteOptions
        }
        renderInput={(params) => (
          <TextField {...params} label={props.schema.title ? labelRender : null} />
        )}
        onChange={(_e, value) => {
          if (value?.id) {
            props.onChange(value?.id);
          }
        }}
        value={val}
        openOnFocus
        isOptionEqualToValue={(option, value) => option?.label === value}
      />
    </FormControl>
  );
};

export default CustomSelectFontWidget;
