import { KpkApiAdvancedSearchCriteria } from "@keepeek/api-client";
import { advancedSearchCriteriasFetcherSelector, fetcherModeAtom } from "@keepeek/commons";
import { selector } from "recoil";

export const advancedSearchCriteriasSelector = selector<KpkApiAdvancedSearchCriteria[] | undefined>(
  {
    key: "AdvancedSearchCriteriasSelector",
    get: async ({ get }) => {
      const { getAdvancedSearchCriterias } = get(
        advancedSearchCriteriasFetcherSelector(get(fetcherModeAtom)),
      );

      return await getAdvancedSearchCriterias();
    },
  },
);
