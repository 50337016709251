/**
 * Problem as defined in RFC7807, but extending error to keep track of the line where it's thrown.
 */
export default class Problem extends Error {
  /**
   * Must be an uri or about:blank.
   */
  type: string;

  title: string;

  status: number;

  detail?: string;

  /**
   * The original error thrown.
   */
  originalError?: Error;

  metadata?: object;

  constructor(
    type: string,
    title: string,
    status: number,
    detail?: string,
    originalError?: Error,
    metadada?: object,
  ) {
    super(detail);
    this.type = type;
    this.title = title;
    this.status = status;
    this.detail = detail;
    this.originalError = originalError;
    this.metadata = metadada;
  }
}
