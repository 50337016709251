import React, { useState } from "react";

import { getTranslatedAndSortedMediaFields, MediaField, useSheetFields } from "@keepeek/commons";
import { Autocomplete, FormControl, LinearProgress, TextField } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { SxProps } from "@mui/system";
import { Widget, WidgetProps } from "@rjsf/utils";
import { getI18n, useTranslation } from "react-i18next";

import { KAdminFields } from "../../../../models/configuration/types/kAdminFields";
import { KAdminMediaFields } from "../../../../models/configuration/types/kAdminMediaFields";
import { convertToFieldList } from "../../../field-utils";

const CustomFieldsSelectWidget: Widget = function ({
  label,
  options,
  onChange,
  required,
  value,
  schema,
}: WidgetProps) {
  const { loading, sheetFields } = useSheetFields();
  const { t } = useTranslation();

  const translatedAndSortedMediaFields: KAdminMediaFields[] = getTranslatedAndSortedMediaFields();

  const fieldOrSection = schema["fieldOrSection"];

  const [unknownField, setUnknownField] = useState<boolean>(false);

  if (loading) {
    return <LinearProgress />;
  }

  // TODO: Those fields create issues in the Refront. To avoid this we remove them until it is fixed. See RF-2444
  const brokenMediaFields = [
    MediaField.ANNOTATION,
    MediaField.ORIENTATION,
    MediaField.ATTACHMENT_COUNTS,
    MediaField.PROVIDER_NAME,
  ];
  const mediaFieldsWithoutBrokenMediaFields = translatedAndSortedMediaFields.filter(
    (field) => !brokenMediaFields.includes(field.id as MediaField),
  );

  let completeFieldList: KAdminFields[] = [];
  if (options?.fieldListType) {
    if (options.fieldListType === "functional") {
      completeFieldList = convertToFieldList(sheetFields, []);
    } else if (options.fieldListType === "technical") {
      completeFieldList = convertToFieldList([], mediaFieldsWithoutBrokenMediaFields);
    }
  } else {
    completeFieldList = convertToFieldList(sheetFields, mediaFieldsWithoutBrokenMediaFields);
  }

  const labelRender = required && label ? `${label}*` : label;

  const formControlStyle: SxProps<Theme> = {
    marginTop: 1,
    marginBottom: 1,
  };

  if (!fieldOrSection || fieldOrSection === "field") {
    const valueComputed = () => {
      if (value) {
        value = value.trim();
        const field = completeFieldList.find((f) => f.id === value);
        if (field) {
          return `${field.name} [${field.id}] (${field.type})`;
        }
        return value.includes(getI18n().t("unknownField"))
          ? value
          : `${value} (${getI18n().t("unknownField")})`;
      }
    };
    return (
      <FormControl sx={formControlStyle}>
        <Autocomplete
          options={completeFieldList.map((field) => ({
            id: field.id,
            label: `${field.name} [${field.id}] (${field.type})`,
          }))}
          renderInput={(params) => (
            <TextField
              onKeyUp={(_e) => {
                setUnknownField(true);
              }}
              onBlur={(_e) => {
                if (unknownField) {
                  setUnknownField(false);
                  onChange(_e.target.value);
                }
              }}
              {...params}
              label={schema.title ? labelRender : null}
            />
          )}
          onChange={(_e, value) => {
            if (value?.id) {
              onChange(value?.id);
              setUnknownField(false);
            }
          }}
          value={valueComputed()}
          openOnFocus
          isOptionEqualToValue={(option, value) => option?.label === value}
          freeSolo
          autoSelect
        />
      </FormControl>
    );
  } else {
    return (
      <FormControl sx={formControlStyle}>
        <TextField
          value={value}
          label={labelRender}
          helperText={t("admin:fieldsSectionsItem.section.value.description")}
          onChange={({ target }) => onChange(target.value)}
        />
      </FormControl>
    );
  }
};

export default CustomFieldsSelectWidget;
