import { ParsedUrlQueryInput } from "querystring";

import { useEffect } from "react";

import { BusinessFilter, BusinessFilterExtraParams, hasActiveValues } from "@keepeek/commons";
import { useRouter } from "next/router";
import { useRecoilState } from "recoil";

import { PagesPath } from "../../../containers/App/utils";
import { lbfState as lbfStateAtom } from "../atoms/lbf";
import { encodeToLightBusinessFilters } from "../utils/lbf/encoding";

/**
 * Hook to init the LBF concept
 * This is supposed to be called only once in a page (hence the "init") to avoid multiple useEffect for nothing #perf
 * It take the query lbf from next router and set it to an atom to keep NextRouter & recoil in sync
 * @author JBO
 */
export const useLBFInit = () => {
  const { query } = useRouter();
  const [, setLBFState] = useRecoilState(lbfStateAtom);

  useEffect(() => {
    setLBFState((query.lbf || "") as string);
  }, [query, setLBFState]);
};

/**
 * !!DO NOT USE THIS HOOK DIRECTLY!! use useActiveFilters instead.
 * This hook help to set the LBF into the query.
 * We cannot use the selector to set the LBF because we're using the next router function to set the LBF
 * @author JBO
 */
export const useLBF = (): {
  setLBF: (filters: BusinessFilter[], extraParams?: BusinessFilterExtraParams) => void;
} => {
  const router = useRouter();
  /**
   * Function to set the LBF
   * We need to pass trough this function to set the LBF with Next Router
   * It will automatically trigger the set of the LBF Atom with the useEffect listening to the query
   * @author JBO
   *
   * @param {BusinessFilter[]} filters
   */
  const setLBF = (filters: BusinessFilter[], extraParams?: BusinessFilterExtraParams) => {
    const clearFromEmptyValues = filters.filter((f) => hasActiveValues(f.filter));
    const query: ParsedUrlQueryInput = { ...router.query, ...extraParams };
    // We will update the lbf so we can delete it
    if ("lbf" in query) {
      delete query.lbf;
    }
    if (clearFromEmptyValues.length !== 0) {
      Object.assign(query, { lbf: encodeToLightBusinessFilters(clearFromEmptyValues) });
    }
    router.push({
      pathname: router.pathname.includes(PagesPath.BASKETS)
        ? router.pathname
        : PagesPath.SEARCH_PAGE,
      query,
    });
  };

  return {
    setLBF,
  };
};
