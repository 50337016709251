import React, { FC } from "react";

import { FieldProps } from "@rjsf/utils";

const CustomWidgetKHeader: FC<React.PropsWithChildren<FieldProps>> = function (props: FieldProps) {
  const { ObjectField } = props.registry.fields;
  const searchBarActive: boolean =
    props.formData.kHeaderWidgetActions?.kHeaderWidgetActionsSearchBar?.active;

  const withoutSearchBarUISchema = {
    ...props.uiSchema,
    kHeaderWidgetActions: {
      kHeaderWidgetActionsSearchBar: {
        inputBorderRadius: {
          "ui:widget": "hidden",
        },
        backgroundColor: {
          "ui:widget": "hidden",
        },
        textColor: {
          "ui:widget": "hidden",
        },
        fields: {
          "ui:widget": "hidden",
        },
      },
    },
  };

  if (searchBarActive) {
    return <ObjectField {...props} uiSchema={props.uiSchema} />;
  }
  return <ObjectField {...props} uiSchema={withoutSearchBarUISchema} />;
};

export default CustomWidgetKHeader;
