import * as Sentry from "@sentry/browser";
import { BrowserOptions } from "@sentry/browser/types";
import { Integrations } from "@sentry/tracing";
import { SamplingContext, Options } from "@sentry/types";
import getConfig from "next/config";

import { BUILD_TIMESTAMP } from "../../build-timestamp";
import { PublicRuntimeConfig } from "../config/publicRuntimeConfigUtils";

export const environmentSentry = (customerId: string): string => {
  if (process.env.NODE_ENV === "development") {
    return "development";
  }
  if (customerId === "keepeek") {
    return "staging";
  }
  return "production";
};

export const dsnSentry = (devTest: boolean): string => {
  // do not track in dev
  if (process.env.NODE_ENV === "development" && !devTest) {
    return "";
  }
  return "https://b0e49e62320e486a8c435ac8819d2d4d@o1112474.ingest.sentry.io/6143626";
};

// See this doc: https://gist.github.com/impressiver/5092952
// Ignore list based off: https://gist.github.com/1878283
const ravenOptions = {
  // Will cause a deprecation warning, but the demise of `ignoreErrors` is still under discussion.
  // See: https://github.com/getsentry/raven-js/issues/73
  ignoreErrors: [
    // Random plugins/extensions
    "top.GLOBALS",
    // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
    "originalCreateNotification",
    "canvas.contentDocument",
    "MyApp_RemoveAllHighlights",
    "http://tt.epicplay.com",
    "Can't find variable: ZiteReader",
    "jigsaw is not defined",
    "ComboSearch is not defined",
    "http://loading.retry.widdit.com/",
    "atomicFindClose",
    // Facebook borked
    "fb_xd_fragment",
    // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
    // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
    "bmi_SafeAddOnload",
    "EBCallBackMessageReceived",
    // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
    "conduitPage",
    // Generic error code from errors outside the security sandbox
    // You can delete this if using raven.js > 1.0, which ignores these automatically.
    "Script error.",
  ],
  ignoreUrls: [
    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,
    // Woopra flakiness
    /eatdifferent\.com\.woopra-ns\.com/i,
    /static\.woopra\.com\/js\/woopra\.js/i,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    // Other plugins
    /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
    /webappstoolbarba\.texthelp\.com\//i,
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
    // Api Health Check
    /api\/health/i,
  ],
};

const tracesSampler = (
  samplingContext: SamplingContext,
  devTest: boolean,
  tracesSampleRate?: number,
): number => {
  if (process.env.NODE_ENV === "development" && !devTest) {
    return 0;
  }
  // Reject any "/api/health" related
  if (samplingContext.transactionContext.name == "GET /api/health") {
    return 0; // never send transactions with name GET /api/health
  }
  return tracesSampleRate || 0.05;
};

export type InitSentryClient = {
  environmentColor?: string;
  tracesSampleRate?: string;
  devTest?: boolean;
  dsn?: string;
  integrations?: boolean;
};

export const initSentryClient = ({
  environmentColor,
  tracesSampleRate = process.env.NEXT_PUBLIC_SENTRY_TRACESSAMPLERATE,
  devTest = false,
  integrations = false,
}: InitSentryClient): BrowserOptions => {
  const customerId = process.env.NEXT_PUBLIC_CUSTOMER_ID || "";
  const tracesSampleRateNumber = tracesSampleRate ? Number(tracesSampleRate) : 0.05;
  return {
    dsn: dsnSentry(devTest),
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampler: (opt) => tracesSampler(opt, devTest, tracesSampleRateNumber),
    debug: devTest || false,
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
    release: process.env.NEXT_PUBLIC_CORE_VERSION,
    environment: environmentSentry(customerId),
    initialScope: {
      tags: {
        version: process.env.NEXT_PUBLIC_CORE_VERSION,
        buildTimestamp: BUILD_TIMESTAMP,
        customerId,
        apiEndPoint: process.env.NEXT_PUBLIC_API_ENDPOINT,
        environmentColor,
      },
    },
    beforeSend: (event) => {
      // Reject any "/api/health" related
      if (JSON.stringify(event).includes("/api/health")) {
        return null;
      }
      return event;
    },
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate:
      process.env.NODE_ENV === "development" && !devTest ? 0 : tracesSampleRateNumber,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
    integrations: integrations
      ? [new Sentry.Replay(), new Integrations.BrowserTracing()]
      : undefined,
    ...ravenOptions,
  };
};

export const initSentryServer = (): Options => {
  const { publicRuntimeConfig }: { publicRuntimeConfig: PublicRuntimeConfig } = getConfig();
  return initSentryClient({
    environmentColor: publicRuntimeConfig.environment,
    tracesSampleRate: publicRuntimeConfig.sentry.tracesSampleRate,
    devTest: publicRuntimeConfig.sentry.devTest,
  });
};
