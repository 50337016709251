import { FrontEditPageSection } from "@keepeek/refront-components";

import FrontEditEditionForm from "../../../containers/FrontEdit/FrontEditEditionForm";
import { CurrentEditKey } from "../atoms/frontEditCurrentEditKeys";

type UseFrontEditContentList = {
  contentFromAdminSections: FrontEditPageSection[];
};

export const useFrontEditContentList = ({
  keys,
}: {
  keys: CurrentEditKey[];
}): UseFrontEditContentList => {
  const contentFromAdminSections: FrontEditPageSection[] = [
    {
      editableContents: [<FrontEditEditionForm key="1" keys={keys} displayButton={false} />],
    },
  ];
  return {
    contentFromAdminSections,
  };
};
