import { FC } from "react";

import { FieldProps } from "@rjsf/utils";
import { isEmpty } from "lodash";
import cloneDeep from "lodash/cloneDeep";

import {
  Language,
  TranslationSchema,
} from "../../../../models/configuration/components/editoWidget";
import { LocaleLinksSchema } from "../../../../models/configuration/components/footerWidget";
import { Locale } from "../../../../models/configuration/definitions/footer";
import { useUILocals } from "../../../../providers/config/hooks/useUILocals";

/**
 * Customized translation array field allowing to offer all the languages available on the front end.
 * This field is only used on a "TranslationSchema" type schema
 */
export const CustomTranslationArrayField: FC<React.PropsWithChildren<FieldProps>> = function (
  props,
) {
  const uiLocals = useUILocals();
  const { registry, formData, schema } = props;
  const { ArrayField } = registry.fields;
  const emptyFormData = !!formData?.find((fd: any) => isEmpty(fd));
  const customFormData: Array<TranslationSchema | LocaleLinksSchema> = emptyFormData
    ? []
    : cloneDeep<Array<TranslationSchema>>(formData || []);
  uiLocals.forEach((local) => {
    // we must adapt the algorithm according to the name that is used in the schema to save the language :
    // LocaleLinksSchema case :
    // @ts-ignore
    if (schema.items?.properties?.locale) {
      if (!customFormData?.some((data) => "locale" in data && data.locale === local)) {
        customFormData?.push({
          locale: local as Locale,
          links: [],
        });
      }
      // TranslationSchema case :
      // @ts-ignore
    } else if (schema.items?.properties?.language) {
      if (!customFormData?.some((data) => "language" in data && data.language === local)) {
        customFormData?.push({
          language: local as Language,
        });
      }
    }
  });

  return <ArrayField {...props} formData={customFormData} />;
};
