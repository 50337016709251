import { sanitizeUrl } from "@braintree/sanitize-url";
import { isLocalURL } from "next/dist/shared/lib/router/router";
import { isAbsoluteUrl } from "next/dist/shared/lib/utils";
import sanitize from "sanitize-html";

import { PagesPath } from "../../containers/App/utils";

const DEFAULT_WAIT_MS_BEFORE_DISPLAY_ALERT = 20000;

// List of authorized pages
const authorizedPages = [
  PagesPath.HOME_PAGE,
  PagesPath.ELEMENT_PAGE,
  PagesPath.SEARCH_PAGE,
  PagesPath.REDIRECT,
  PagesPath.ADMIN,
];

/**
 * Verify if a path is safe to use
 * @param unverifiedPath - The path to be verified
 * @returns The verified path if it is safe to use, otherwise undefined
 */
export const verifyPath = (unverifiedPath: string | undefined): string | undefined => {
  if (!unverifiedPath) {
    return undefined;
  }

  // Sanitize the path
  const verifiedPath = sanitizeUrl(sanitize(unverifiedPath));

  // Check if the path is safe to use
  if (
    verifiedPath.includes("javascript") ||
    verifiedPath.includes("data") ||
    verifiedPath.includes("mailto") ||
    verifiedPath.startsWith("http") ||
    verifiedPath.startsWith("www") ||
    !isLocalURL(verifiedPath) ||
    isAbsoluteUrl(verifiedPath) ||
    !authorizedPages.some((page) => verifiedPath.startsWith(page))
  ) {
    return undefined;
  }

  return verifiedPath;
};

export const isAlertMessageEnabled = (msBeforeDisplayMessage?: number): boolean =>
  msBeforeDisplayMessage !== undefined && msBeforeDisplayMessage > 0;

export const getWaitMsBeforeDisplayAlert = (
  loading: boolean,
  specificWaitMs?: string,
): undefined | number => {
  if (loading) {
    return undefined;
  } else if (specificWaitMs) {
    return parseInt(specificWaitMs);
  }
  return DEFAULT_WAIT_MS_BEFORE_DISPLAY_ALERT;
};
