import React, { useEffect, useMemo } from "react";

import {
  currentLocaleAtom,
  getKpkMetaFieldLabelByFieldName,
  MediaField,
  sheetFieldsSelector,
} from "@keepeek/commons";
import { SanitizeHtml, SortElement } from "@keepeek/refront-components";
import { ListItem, ListItemIcon } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useRecoilState, useRecoilValue, useRecoilValueLoadable } from "recoil";

import { KIcon } from "../../../components/common/KIcon";
import { CONFIGURATION_SECTION_PATH } from "../../../components/layout/adminMenu";
import { WidgetConfigurations } from "../../../containers/widgets/models";
import logger from "../../../lib/logger-utils";
import { Template } from "../../../models/configuration/definitions/template";
import { Global, Visibility } from "../../../models/configuration/global";
import { TemplateSchema } from "../../../models/configuration/pages/home";
import { Direction, Field } from "../../../models/configuration/pages/search";
import { useLoadableErrorLogger } from "../../utils";
import { configSectionPeristenceAtom } from "../atoms/init";
import {
  configSectionDataByPathSelector,
  configSectionKTemplateByPosition,
  configSectionPageSearchSelector,
} from "../selectors";
import { isPageVisible, KTemplatesTemplatesName } from "../utils";

const SORT_LIST_I18N_KEY = "sort-list";
export const SORT_LIST_DEFAULT_FIELDS: Field[] = [
  {
    field: MediaField.IMPORT_DATE,
    direction: Direction.Desc,
    default: true,
  },
  {
    field: "title",
    direction: Direction.Asc,
  },
  {
    field: "title",
    direction: Direction.Desc,
  },
];

export const useSortConfig = (): { sortList: SortElement[] } => {
  const { t } = useTranslation();
  const searchConfig = useRecoilValue(configSectionPageSearchSelector);
  const sheetFields = useRecoilValueLoadable(sheetFieldsSelector);

  const ret: SortElement[] = useMemo(() => {
    if (sheetFields.state === "hasValue") {
      const newRet: SortElement[] = [];
      const fields: Field[] =
        (searchConfig?.sort?.fields && searchConfig?.sort?.fields.length > 0
          ? searchConfig?.sort?.fields
          : SORT_LIST_DEFAULT_FIELDS) || SORT_LIST_DEFAULT_FIELDS;
      fields.forEach((f) => {
        const genericTranslation = t(`sort-list.generic.${f.direction}`, {
          field: getKpkMetaFieldLabelByFieldName(sheetFields.contents, f.field).toLowerCase(),
        });
        // try find specific translation, if nothing found, use genericTranslation :
        const translation = t(
          `${SORT_LIST_I18N_KEY}.${f.field}.${f.direction}`,
          genericTranslation,
        );

        newRet.push({
          label: (
            <ListItem>
              <SanitizeHtml html={translation} />
              <ListItemIcon>
                <KIcon>{Direction.Asc === f.direction ? "arrow_upward" : "arrow_downward"}</KIcon>
              </ListItemIcon>
            </ListItem>
          ),
          value: `${f.field}-${f.direction}`,
        });
      });
      return newRet;
    } else {
      return [];
    }
  }, [searchConfig?.sort?.fields, sheetFields.contents, sheetFields.state, t]);

  return {
    sortList: ret,
  };
};

type UseKTemplatesConfParams = {
  templatePagePosition?: CONFIGURATION_SECTION_PATH;
  templatesName?: KTemplatesTemplatesName;
  defaultTemplates?: TemplateSchema[];
};

type UseKTemplatesConfReturn = {
  templates: Template[] | undefined;
  widgetsConfiguration: WidgetConfigurations | undefined;
  loading: boolean;
};

export const useKTemplatesConf = ({
  templatesName = KTemplatesTemplatesName.Templates,
  templatePagePosition,
  defaultTemplates = [],
}: UseKTemplatesConfParams): UseKTemplatesConfReturn => {
  const locale = useRecoilValue(currentLocaleAtom);
  let defaultTemplatesStringify: string | undefined;
  try {
    defaultTemplatesStringify = JSON.stringify(defaultTemplates);
  } catch (error) {
    logger.error("useKTemplatesConf - can't stringify default templates", error);
  }

  const configQuery = useRecoilValueLoadable(
    configSectionKTemplateByPosition({
      locale,
      templatePagePosition,
      templatesName,
      defaultTemplatesStringify,
    }),
  );
  useLoadableErrorLogger(configQuery, `useKTemplatesConf configQuery ${templatesName}`);

  const loading = configQuery.state === "loading";

  const { templates, widgetsConfiguration } =
    configQuery.state === "hasValue"
      ? configQuery.contents
      : { templates: undefined, widgetsConfiguration: undefined };

  return {
    templates,
    widgetsConfiguration,
    loading,
  };
};

type UseConfiguration<T, K extends undefined | true = undefined> = K extends true
  ? (T | undefined) & { useConfigurationLoadingState: boolean }
  : T | undefined;

/**
 * Serve admin configuration with persistence mecanism during loading state
 * @param configSectionName CONFIGURATION_SECTION_PATH: path in admin configuration
 * @returns admin config or undefined
 */
export function useConfiguration<T, K extends undefined | true = undefined>(
  configSectionName: CONFIGURATION_SECTION_PATH,
  withLoadingState?: K,
): UseConfiguration<T, K> {
  const query = useRecoilValueLoadable<UseConfiguration<T>>(
    configSectionDataByPathSelector(configSectionName),
  );
  const [persistor, setPersistor] = useRecoilState<UseConfiguration<T>>(
    configSectionPeristenceAtom(configSectionName),
  );

  useEffect(() => {
    if (query.state === "hasValue") {
      setPersistor(query.contents);
    } else {
      setPersistor((currentValue) => {
        return { ...currentValue, useConfigurationLoadingState: true } as UseConfiguration<T, true>;
      });
    }
  }, [query.contents, query.state, setPersistor]);

  if (withLoadingState === true) {
    return persistor as UseConfiguration<T, true>;
  } else {
    return persistor as UseConfiguration<T, K>;
  }
}

/**
 * Use page visible
 */
export const usePageVisible = () => {
  const config = useConfiguration<Global>(CONFIGURATION_SECTION_PATH.GLOBAL);

  return {
    homeVisible: isPageVisible(config, Visibility.Home),
    elementVisible: isPageVisible(config, Visibility.Element),
    searchVisible: isPageVisible(config, Visibility.Search),
  };
};
