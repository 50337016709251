import { IChangeEvent } from "@rjsf/core";
import { UiSchema } from "@rjsf/utils";
import { JSONSchema7Definition } from "json-schema";
import { debounce } from "lodash";
import { useRecoilValue, useRecoilValueLoadable, useSetRecoilState } from "recoil";

import { getPathFromSectionDataKey } from "../../../containers/FrontEdit/utils";
import { frontEditState } from "../atoms/frontEdit";
import {
  CurrentContentEdit,
  frontEditCurrentContentEditsState,
} from "../atoms/frontEditCurrentContentEdits";
import { CurrentEditKey } from "../atoms/frontEditCurrentEditKeys";
import { getDataOfMultipleSectionsSelector } from "../selectors/schema";
import { replaceItemAtIndex } from "../utils";
import useFrontEditModal from "./useFrontEditModal";

export type SectionData = {
  key: CurrentEditKey;
  configurationData: any;
  jsonSchema: JSONSchema7Definition | JSONSchema7Definition[] | undefined;
  jsonUiSchema: UiSchema | undefined;
};

type UseFrontEditCurrentEdit = {
  sectionsData: SectionData[] | null;
  loading: boolean;
  handleSubmit: () => void;
  handleChangeAndSubmit: (e: IChangeEvent, sectionData: SectionData) => void;
  handleChange: (e: IChangeEvent, sectionData: SectionData) => void;
};

type UseFrontEditCurrentEditOptions = {
  forceEditMode?: boolean;
};

const debounceCallback = debounce((cb) => {
  cb();
}, 500);

export default function useFrontEditCurrentEdit(
  keys: CurrentEditKey[],
  options?: UseFrontEditCurrentEditOptions,
): UseFrontEditCurrentEdit {
  const { forceEditMode } = options ?? { forceEditMode: false };
  let localContentsEdits: CurrentContentEdit[] = [];
  const { setIsOpen } = useFrontEditModal();
  const frontEdit = useRecoilValue(frontEditState);
  const sectionsDataLoadable = useRecoilValueLoadable(
    // Avoid loading if we're not in edit mode
    getDataOfMultipleSectionsSelector({ keys: frontEdit || forceEditMode ? keys : [] }),
  );
  const sectionsData =
    sectionsDataLoadable.state === "hasValue" ? sectionsDataLoadable.contents : null;

  const loading = sectionsDataLoadable.state === "loading";

  const setCurrentContentEdits = useSetRecoilState(frontEditCurrentContentEditsState);

  const updateConfigDuringFrontEdit = () => {
    localContentsEdits.forEach((lce) => {
      setCurrentContentEdits((currentContentEdits) => {
        const index = currentContentEdits.findIndex(
          (currentContentEdit) => currentContentEdit.endpoint === lce.endpoint,
        );
        if (index === -1) {
          return [...currentContentEdits, lce];
        } else {
          return replaceItemAtIndex(currentContentEdits, index, lce);
        }
      });
    });
  };

  const handleSubmit = () => {
    // Send the local modification to the whole content to publish
    updateConfigDuringFrontEdit();
    // Close the modal in case it was open
    setIsOpen(false);
  };

  const handleChangeAndSubmit = (e: IChangeEvent, sectionData: SectionData) => {
    handleChange(e, sectionData);
    debounceCallback.cancel();
    debounceCallback(handleSubmit);
  };

  const handleChange = (e: IChangeEvent, sectionData: SectionData) => {
    const { key, jsonSchema, jsonUiSchema } = sectionData;
    const endpoint = getPathFromSectionDataKey(key);
    const index = localContentsEdits.findIndex(
      (localContentsEdit) => localContentsEdit.endpoint === endpoint,
    );
    if (index !== -1) {
      localContentsEdits = replaceItemAtIndex(localContentsEdits, index, {
        key,
        endpoint,
        data: e.formData,
        jsonSchema,
        jsonUiSchema,
      });
    } else {
      localContentsEdits = [
        ...localContentsEdits,
        {
          key,
          endpoint,
          data: e.formData,
          jsonSchema,
          jsonUiSchema,
        },
      ];
    }
  };

  return {
    sectionsData,
    loading,
    handleSubmit,
    handleChangeAndSubmit,
    handleChange,
  };
}
