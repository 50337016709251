import { useEffect, useMemo } from "react";

import {
  BusinessFilterNature,
  DataViewKey,
  KpkFilterType,
  buildOptimizedLightDataviewElementsSelector,
  dataViewElementsTotalCountSelector,
  useStatsSearchHit,
} from "@keepeek/commons";
import { useRecoilState, useRecoilValue } from "recoil";

import logger from "../../../lib/logger-utils";
import { activeBusinessFiltersFromURLSelector } from "../../businessFilter/selectors/activeFilters";
import { initConfigApiAtom } from "../../config/atoms/init";
import { configSectionComponentBusinessFilterSelector } from "../../config/selectors";
import { statsFolderIdForFolderHit, statsSearchValueForTextAtom } from "../atoms";

const key = DataViewKey.SEARCH;
// this hook should only exist one time per page
// if not it will send the stats as much times as we have instanced it
export const useStatsQueueListener = () => {
  const filters = useRecoilValue(activeBusinessFiltersFromURLSelector);
  const [folderIdForFolderHit, setFolderIdForFolderHit] = useRecoilState(statsFolderIdForFolderHit);
  const [searchValueForText, setSearchValueForText] = useRecoilState(statsSearchValueForTextAtom);

  const initConfig = useRecoilValue(initConfigApiAtom);
  const { sendFolderSearchHit, sendTextSearchHit } = useStatsSearchHit({
    ip: initConfig?.clientIp,
  });
  const filtersConfiguration =
    useRecoilValue(configSectionComponentBusinessFilterSelector)?.filters ?? null;

  /**
   * This memo variable takes the folder|text we have sent in queu
   * If it detect  it into the actives filters, then it is true
   * @author JBO
   *
   * @type {boolean}
   */
  const hasFiltersIncluded: boolean = useMemo(
    () =>
      filters.some(
        (f) =>
          // searchValueForText case
          (f.filter.id === BusinessFilterNature.FULL_TEXT &&
            f.filter.values.some((v) => v.label === searchValueForText)) ||
          // folderIdForFolderHit case
          (f.filter.type === KpkFilterType.Folder &&
            f.filter.values.some((v) => v.id === folderIdForFolderHit)),
      ),
    [filters, searchValueForText, folderIdForFolderHit],
  );

  /**
   * This recoil value receive the total count number of a given dataView
   * It has filters conditionnaly given to activeFilter or null to avoid calling it with no need
   * @author JBO
   */
  const totalCount = useRecoilValue(
    dataViewElementsTotalCountSelector(
      buildOptimizedLightDataviewElementsSelector({
        key,
        filters: hasFiltersIncluded ? filters : null,
        filtersConfiguration,
      }),
    ),
  );

  // Folder case
  // If we have a folderId waiting to be sent, then resolve the count and send it
  useEffect(() => {
    if (folderIdForFolderHit && totalCount >= 0) {
      logger.debug(
        `useStatsSearchHitWithResult - sending stat for folder from queue with folderId ${folderIdForFolderHit} and total count of ${totalCount}`,
      );
      sendFolderSearchHit({ folderId: folderIdForFolderHit, resultCount: totalCount });
      setFolderIdForFolderHit(undefined);
    }
  }, [folderIdForFolderHit, sendFolderSearchHit, setFolderIdForFolderHit, totalCount]);

  // Search by text case
  // If we have a text waiting to be sent, then resolve the count and send it
  useEffect(() => {
    if (searchValueForText && totalCount >= 0) {
      logger.debug(
        `useStatsSearchHitWithResult - sending stat for text search from queue with value ${searchValueForText} and total count of ${totalCount}`,
      );
      sendTextSearchHit({ searchValue: searchValueForText, resultCount: totalCount });
      setSearchValueForText(undefined);
    }
  }, [searchValueForText, sendTextSearchHit, setSearchValueForText, totalCount]);
};
