import { atom } from "recoil";

import { CurrentEditKey } from "./frontEditCurrentEditKeys";

export type CurrentContentEdit = {
  key: CurrentEditKey;
  endpoint: string;
  data: any;
  jsonSchema: any;
  jsonUiSchema: any;
};

export const frontEditCurrentContentEditsState = atom<CurrentContentEdit[]>({
  key: "frontEditCurrentContentEditsState",
  default: [],
});
