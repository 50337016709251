import React from "react";

import { SanitizeHtml } from "@keepeek/refront-components";
import { Box, Typography } from "@mui/material";
import { getUiOptions, Widget, WidgetProps } from "@rjsf/utils";
import dynamic from "next/dynamic";
import { useTranslation } from "react-i18next";

// Can't load wysiwyg from SSR because of "window" use
const WysiwygNoSsr = dynamic(() => import("./Wysiwyg"), { ssr: false });

const CustomWysiwyg: Widget = function ({ onChange, value, label, uiSchema }: WidgetProps) {
  const { t } = useTranslation("admin");

  const wysiwygUiOptions = getUiOptions(uiSchema);
  const activeBase64image = !!wysiwygUiOptions.base64image;

  return (
    <Box data-testid="CustomWysiwyg">
      <Typography variant="subtitle1">
        <SanitizeHtml html={t(label)} />
      </Typography>
      <Box sx={{ minHeight: "400px" }}>
        <WysiwygNoSsr value={value} onChange={onChange} activeBase64image={activeBase64image} />
      </Box>
    </Box>
  );
};

export default CustomWysiwyg;
