import React, { FC, ReactNode, useMemo } from "react";

import { useClearSelectionRules } from "@keepeek/commons";
import { useTheme } from "@mui/material";
import { useRouter } from "next/router";
import NextNProgress from "nextjs-progressbar";

import { useInitOverride } from "../../providers/admin/config/useInitOverride";
import { useActiveBusinessFiltersSync } from "../../providers/businessFilter/hooks/useActiveBusinessFiltersSync";
import { useSetActiveBusinessFiltersSync } from "../../providers/businessFilter/hooks/useSetActiveBusinessFiltersSync";
import { useInitConfig } from "../../providers/config/hooks/init";
import { useInitDataViewConfiguration } from "../../providers/config/hooks/useInitDataViewConfiguration";
import { useInitShareManagerConfiguration } from "../../providers/config/hooks/useInitShareManagerConfiguration";
import { useFetchers } from "../../providers/fetcher/hooks";
import { useCurrentLocale } from "../../providers/i18n/hooks";
import { useMatomo } from "../../providers/matomo/hooks";
import { usePageRouterEvents } from "../../providers/page/hooks/usePageRouterEvents";
import { useSentry } from "../../providers/tools/sentry/hooks";
import Didomi from "./Didomi";
import GoogleAnalytics from "./GoogleAnalytics";
import StatsListener from "./StatsListener";
import { PagesPath } from "./utils";

type ConsumerProps = { children: ReactNode };

// You can use all hooks here since every providers has been set/loaded from the component above
const Consumer: FC<ConsumerProps> = function ({ children }) {
  const router = useRouter();

  const clearSelectionRulesParams = useMemo(() => {
    return {
      isSearchContext: () =>
        router.pathname === PagesPath.SEARCH_PAGE || router.pathname === PagesPath.ELEMENT_PAGE,
    };
  }, [router.pathname]);

  // register router events in recoil
  usePageRouterEvents();
  // init Admin config
  useInitConfig();
  // Consume theme
  const theme = useTheme();
  // init Sentry
  useSentry();
  // Manage providers fetchers update while browsing between logged and share mode
  useFetchers();
  // init current locale
  useCurrentLocale();
  // Rules for clearing a selection in dataView
  useClearSelectionRules(clearSelectionRulesParams);
  // Inject override Json Config Section to refront-customers
  useInitOverride();
  // Init Matomo
  useMatomo();
  // Init dataview conf
  useInitDataViewConfiguration();
  // Init share manager conf
  useInitShareManagerConfiguration();
  // Sync function to set the active business filters
  useSetActiveBusinessFiltersSync();
  // Sync active business
  useActiveBusinessFiltersSync();
  return (
    <>
      <StatsListener />
      <NextNProgress color={theme.palette.primary.main} />
      <Didomi />
      <GoogleAnalytics />
      {children}
    </>
  );
};

export default Consumer;
