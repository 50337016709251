import { useEffect } from "react";

import { removeQueryStringAndHash } from "@keepeek/commons";
import { useRouter } from "next/router";
import { useSetRecoilState } from "recoil";

import { pageNameAtom } from "../atoms";
import { mapPathNameToPageName } from "../utils";

export type UsePageRouterEvents = {};

/**
 * Hook used to register events on router to avoid extra renders.
 */
export const usePageRouterEvents = (): UsePageRouterEvents => {
  const router = useRouter();
  const setPageName = useSetRecoilState(pageNameAtom);

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      setPageName(mapPathNameToPageName(removeQueryStringAndHash(url)));
    };

    router.events.on("routeChangeStart", handleRouteChange);

    return () => {
      router.events.off("routeChangeStart", handleRouteChange);
    };
  }, [router.events, setPageName]);

  return {};
};
