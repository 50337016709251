import { FC } from "react";

import { i18n } from "i18next";
import { I18nextProvider } from "react-i18next";

import useI18nLanguageSwitch from "./hooks";
import { getInstanceImmediate } from "./i18next/i18next-instance";

const I18nProvider: FC<React.PropsWithChildren<unknown>> = function ({ children }) {
  useI18nLanguageSwitch();

  // At that point the instance should be initialized
  //  - By getServerSideProps if on the server
  //  - By useI18nLanguageSwitch if on the client.
  // So we can get it synchronously
  const instance: i18n = getInstanceImmediate();

  return <I18nextProvider i18n={instance}>{children}</I18nextProvider>;
};

export default I18nProvider;
