import { useRecoilValue } from "recoil";

import { PageName } from "../../../containers/App/utils";
import { pageNameAtom } from "../atoms";

export type UsePage = {
  pageName: PageName;
};
export const usePage = (): UsePage => {
  const pageName = useRecoilValue(pageNameAtom);

  return {
    pageName,
  };
};
