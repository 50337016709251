import { BusinessFilter, LightBusinessFilter, sheetFieldsSelector } from "@keepeek/commons";
import { selector } from "recoil";

import { advancedSearchCriteriasSelector } from "../../advancedSearch/selectors";
import { lbfState } from "../atoms/lbf";
import { convertToBusinessFilter } from "../utils/lbf/convertToBusinessFilter";
import { decodeFilters } from "../utils/lbf/encoding";

/*
 * Selector that take the LBF atom setted by the NextRouter and give BusinessFilter[]
 * @author JBO
 */
export const lbfToBusinessFiltersSelector = selector<BusinessFilter[]>({
  key: "LbfToBusinessFiltersSelector",
  get: ({ get }) => {
    const lbf = get(lbfState);
    const decodedLbf = decodeFilters<LightBusinessFilter[]>(lbf);
    if (!decodedLbf) {
      return [];
    }
    const criterias = get(advancedSearchCriteriasSelector);
    const sheetFields = get(sheetFieldsSelector);
    return convertToBusinessFilter(decodedLbf, sheetFields, criterias);
  },
});
