import { useEffect } from "react";

import { shareManagerConfiguration } from "@keepeek/commons";
import { useRecoilValue, useSetRecoilState } from "recoil";

import { configSectionShareManagerSelector } from "../selectors";

export const useInitShareManagerConfiguration = () => {
  const setShareManagerConfiguration = useSetRecoilState(shareManagerConfiguration);
  const getConfigSectionComponentBusinessFilter = useRecoilValue(configSectionShareManagerSelector);
  useEffect(() => {
    setShareManagerConfiguration({
      unitaryPublicShare: {
        active: getConfigSectionComponentBusinessFilter?.unitaryPublicShare?.active,
      },
    });
  }, [
    getConfigSectionComponentBusinessFilter?.unitaryPublicShare?.active,
    setShareManagerConfiguration,
  ]);
};
