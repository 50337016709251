import { BusinessFilter } from "@keepeek/commons";
import { selector } from "recoil";

import { basketDetailState } from "../atoms/basketDetail";
import { lbfState } from "../atoms/lbf";
import { lbfToBusinessFiltersSelector } from "./lbf";

/**
 * Return the active filters from URL
 * This selector allows to have multiple connectors with the URL
 * Support LBF type at the moment
 * @author JBO
 */
export const activeBusinessFiltersFromURLSelector = selector<BusinessFilter[]>({
  key: "ActiveBusinessFiltersSelector",
  get: ({ get }) => {
    // TODO: for the moment we only support the LBF
    // Add support for a new type of URL filtering, more concise and readable but with more logic behind
    const lbf = get(lbfToBusinessFiltersSelector);
    const basketDetail = get(basketDetailState);
    return basketDetail ? [...lbf, ...basketDetail] : lbf;
  },
});

export const activeRawURLFiltersFromURLSelector = selector<{ lbf: string }>({
  key: "ActiveRawURLFiltersFromURLSelector",
  get: ({ get }) => {
    // TODO: for the moment we only support the LBF
    // Add support for a new type of URL filtering, more concise and readable but with more logic behind
    const lbf = get(lbfState);
    const params = {
      lbf,
    };
    return params;
  },
});
