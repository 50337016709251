export interface Global {
    copyInHTMLMode?:        boolean;
    didomi?:                Didomi;
    gdprValidation?:        GdprValidation;
    googleAnalyticsId?:     string;
    imageOptimization?:     boolean;
    keycloakConfiguration?: KeycloakConfiguration;
    matomo?:                Matomo;
    pages?:                 Pages;
    permanentFilters?:      PermanentFilter[];
    projectInformation?:    ProjectInformation;
    sentry?:                Sentry;
}

export interface Didomi {
    noticeId?: string;
}

export interface GdprValidation {
    actionContent?:       TranslationSchema[];
    backButtonContent?:   TranslationSchema[];
    background?:          Background;
    checkboxContent?:     CheckboxContent[];
    contactContent?:      TranslationSchema[];
    content?:             TranslationSchema[];
    dateGdprUpdate?:      string;
    gdprCurrentResource?: string;
}

export interface TranslationSchema {
    empty?:      boolean;
    language:    Language;
    ressources?: any;
    value?:      string;
}

export enum Language {
    De = "de",
    En = "en",
    Es = "es",
    Fr = "fr",
    Hu = "hu",
    It = "it",
    Nl = "nl",
    Pl = "pl",
    Pt = "pt",
    Ro = "ro",
}

export interface Background {
    color?: string;
    type?:  BackgroundType;
    url?:   URL;
}

export enum BackgroundType {
    Color = "color",
    Image = "image",
    Inherit = "inherit",
    Video = "video",
}

export interface URL {
    id?:  string;
    url?: string;
}

export interface CheckboxContent {
    autoLinking?: boolean;
    content?:     TranslationSchema[];
}

export interface KeycloakConfiguration {
    autoGuestLogin?:                AutoGuestLogin;
    customUrlNotAllowed?:           string;
    displayConnectWithCredentials?: boolean;
    displayConnectWithGuest?:       boolean;
    idp?:                           Idp[];
}

export enum AutoGuestLogin {
    AutoGuestLogin = "AUTO_GUEST_LOGIN",
    AutoGuestLoginWithParam = "AUTO_GUEST_LOGIN_WITH_PARAM",
    Deactivated = "DEACTIVATED",
}

export interface Idp {
    key?:  string;
    name?: string;
}

export interface Matomo {
    matomoUrl?: string;
    siteId?:    string;
}

export interface Pages {
    locales?:    Language[];
    visibility?: Visibility[];
}

export enum Visibility {
    Element = "/element",
    Home = "/home",
    Search = "/search",
}

export interface PermanentFilter {
    fieldType?:         FieldType;
    internalFieldName?: string;
    modifier?:          Modifier;
    showSub?:           boolean;
    type?:              PermanentFilterType;
    values?:            string[];
}

export enum FieldType {
    Mediafield = "MEDIAFIELD",
    Metafield = "METAFIELD",
}

export enum Modifier {
    BeginsWith = "BEGINS_WITH",
    ContainsAll = "CONTAINS_ALL",
    ContainsNone = "CONTAINS_NONE",
    ContainsOne = "CONTAINS_ONE",
    DateCurrentMonth = "DATE_CURRENT_MONTH",
    DateLast30_Days = "DATE_LAST_30_DAYS",
    DateLast7_Days = "DATE_LAST_7_DAYS",
    DateLastMonth = "DATE_LAST_MONTH",
    DateToday = "DATE_TODAY",
    DateYesterday = "DATE_YESTERDAY",
    Empty = "EMPTY",
    EndsWith = "ENDS_WITH",
    Equals = "EQUALS",
    EqualsNoValues = "EQUALS_NO_VALUES",
    EqualsOne = "EQUALS_ONE",
    FromTo = "FROM_TO",
    GroupContainsNone = "GROUP_CONTAINS_NONE",
    GroupContainsOne = "GROUP_CONTAINS_ONE",
    NotEmpty = "NOT_EMPTY",
}

export enum PermanentFilterType {
    Associationsfield = "ASSOCIATIONSFIELD",
    Checkboxfield = "CHECKBOXFIELD",
    Datefield = "DATEFIELD",
    Datetimefield = "DATETIMEFIELD",
    Emailfield = "EMAILFIELD",
    Extensionfield = "EXTENSIONFIELD",
    Filesizefield = "FILESIZEFIELD",
    Folderfield = "FOLDERFIELD",
    Geofield = "GEOFIELD",
    Htmlfield = "HTMLFIELD",
    Listfield = "LISTFIELD",
    Metamodelfield = "METAMODELFIELD",
    Metamodeltypefield = "METAMODELTYPEFIELD",
    Multiplelistfield = "MULTIPLELISTFIELD",
    Numberfield = "NUMBERFIELD",
    Personsfield = "PERSONSFIELD",
    Socialnetworksfield = "SOCIALNETWORKSFIELD",
    Statusfield = "STATUSFIELD",
    Tagsfield = "TAGSFIELD",
    Textarea = "TEXTAREA",
    Textfield = "TEXTFIELD",
    Thesaurusfield = "THESAURUSFIELD",
    Unknonwfield = "UNKNONWFIELD",
    Urlfield = "URLFIELD",
    Usersfield = "USERSFIELD",
}

export interface ProjectInformation {
    expectedLaunchDate?: string;
    inProduction?:       boolean;
}

export interface Sentry {
    deactivated?: boolean;
}
