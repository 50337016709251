import React, { useEffect, useState } from "react";

import { dayjs, useCurrentUi } from "@keepeek/commons";
import { KButton } from "@keepeek/refront-components";
import { Alert, Box, Container, Paper, Typography } from "@mui/material";
import { Field, getUiOptions } from "@rjsf/utils";
import { FilePondErrorDescription, FilePondFile } from "filepond";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import { useSnackbar } from "notistack";
import { FilePond, registerPlugin } from "react-filepond";
import { useTranslation } from "react-i18next";
import { useRecoilState, useSetRecoilState } from "recoil";
import { v4 as uuidv4 } from "uuid";

import {
  apiStoreAtom,
  ApiStoreType,
  dataChangeState,
} from "../../../../providers/admin/config/atoms";
import { addNewValueInApiStoreMap } from "../../../../providers/admin/config/utils";
import { useFileResourceCreate } from "../../../../providers/resource/hooks/useFileResourceCreate";
import { useFileResourceReplace } from "../../../../providers/resource/hooks/useFileResourceReplace";
import logger from "../../../logger-utils";
import ExtensionList from "./components/ExtensionList";
import LoadingInformation from "./components/LoadingInformation";
import ResourceRenderer from "./components/ResourceRenderer";
import { API_RESOURCE_NATURE, getResourceType, LoadingStatus, ResourceType } from "./utils";

import "filepond/dist/filepond.min.css";

type UISchemaType = {
  "sync:field"?: string;
  "sync:type"?: string;
  "sync:value"?: string;
  "sync:canceltype"?: string;
  "sync:cancelvalue"?: string;
};

const getValueFromSchema = (
  schema: UISchemaType | undefined,
  typeKey: string,
  valueKey: string,
  defaultValue: string,
): string => {
  if (schema?.[typeKey] === "date" && !!schema?.[valueKey]) {
    if (schema[valueKey] === "now") {
      return dayjs().utc().toISOString();
    }
    return dayjs(schema[valueKey]).utc().toISOString();
  }
  return schema?.[valueKey] ?? defaultValue;
};

/**
 * Custom field to display a resource selector in any admin page
 */
const CustomSelectResource: Field = function ({ schema, uiSchema, formData, onChange }) {
  const { t } = useTranslation();
  const { currentUi } = useCurrentUi();
  const resourceType: ResourceType = getResourceType(getUiOptions(uiSchema));
  registerPlugin(FilePondPluginFileValidateSize);
  const [resourceUploadState, setResourceUploadState] = useState<LoadingStatus>(LoadingStatus.NONE);
  const { enqueueSnackbar } = useSnackbar();
  const { createResource } = useFileResourceCreate();
  const { replaceResource } = useFileResourceReplace();
  const [apiStore, setApiStore] = useRecoilState<ApiStoreType>(apiStoreAtom);
  const key =
    uiSchema && uiSchema["api:destination"] && uiSchema["api:field"]
      ? `${uiSchema["api:destination"]}:${uiSchema["api:field"]}`
      : "";
  const [currentResourceUrl, setCurrentResourceUrl] = useState<string>(formData?.url);
  const [currentResourceId, setCurrentResourceId] = useState<string>(formData?.id);
  const setDataChange = useSetRecoilState(dataChangeState);
  useEffect(() => {
    if (!key && formData) {
      setCurrentResourceUrl(formData.url);
      setCurrentResourceId(formData.id);
    } else {
      if (
        currentUi &&
        currentUi?.gdprCurrentResources &&
        uiSchema &&
        uiSchema["api:destination"] &&
        uiSchema["api:field"]
      ) {
        const uiGdprResource = apiStore[key] ?? currentUi.gdprCurrentResources;
        setCurrentResourceUrl(uiGdprResource ?? "");
        if (uiGdprResource.lastIndexOf("/") > 0 && uiGdprResource.lastIndexOf(".") > 1) {
          setCurrentResourceId(
            uiGdprResource
              ? uiGdprResource.substring(
                  uiGdprResource.lastIndexOf("/") + 1,
                  uiGdprResource.lastIndexOf("."),
                )
              : "",
          );
        }
      }
    }
  }, [apiStore, currentUi, formData, key, uiSchema]);

  const handleSync = ({ cancel = false }: { cancel?: boolean }) => {
    if (uiSchema?.["sync:field"]) {
      const value = getValueFromSchema(uiSchema as UISchemaType, "sync:type", "sync:value", "");
      const cancelValue = getValueFromSchema(
        uiSchema as UISchemaType,
        "sync:canceltype",
        "sync:cancelvalue",
        value,
      );

      addNewValueInApiStoreMap(
        uiSchema["sync:field"],
        cancel ? cancelValue : value,
        setApiStore,
        setDataChange,
      );
    }
  };

  const handleOnChange = (value: { id: string; url: string }) => {
    onChange(value);
    handleSync({});
  };

  async function handleAddFile(file: FilePondFile): Promise<{
    id: string;
    url: string;
  }> {
    handleSync({});
    if (!file) {
      return Promise.reject();
    }
    if (!currentResourceUrl) {
      const myUuid = uuidv4();
      let id = file.filenameWithoutExtension.replace(/[^a-zA-Z0-9]/g, "");
      id = id.concat("_").concat(myUuid.substring(0, 5));
      const url = await createResource(file.file, API_RESOURCE_NATURE, id);
      if (url) {
        setResourceUploadState(LoadingStatus.LOADED);
        enqueueSnackbar(t("admin:resource.create.success"), {
          variant: "success",
        });
        return { id, url };
      } else {
        setResourceUploadState(LoadingStatus.ERROR);
        return Promise.reject();
      }
    } else {
      const url = await replaceResource(file.file, API_RESOURCE_NATURE, currentResourceId);
      if (url) {
        setResourceUploadState(LoadingStatus.LOADED);
        enqueueSnackbar(t("admin:resource.replace.success"), {
          variant: "success",
        });
        return { id: currentResourceId, url };
      } else {
        setResourceUploadState(LoadingStatus.ERROR);
        return Promise.reject();
      }
    }
  }

  const handleResourceRemove = (): void => {
    if (key) {
      setCurrentResourceUrl("");
      addNewValueInApiStoreMap(key, "", setApiStore, setDataChange);
      setResourceUploadState(LoadingStatus.NONE);
      handleSync({ cancel: true });
    } else {
      handleOnChange({ id: "", url: "" });
    }
  };
  const hideResourceRemoveButton = getUiOptions(uiSchema).hideResourceRemoveButton === true;

  const [confirmation, setConfirmation] = useState(false);

  if (uiSchema?.["ui:needconfirmation"] && !confirmation) {
    const confirmationLabel = t(uiSchema["ui:needconfirmation"]);
    return (
      <Container component={Paper} sx={{ padding: 1 }}>
        <Typography variant="h6">
          {schema.title ? t(schema.title) : t("admin:labels.translation.resource.label")}
        </Typography>
        <ResourceRenderer
          resourceType={resourceType}
          resourceUrl={currentResourceUrl}
          onResourceRemove={() => {}}
          hideResourceRemoveButton={true}
        />
        <Alert severity="error">
          <Typography sx={{ marginBottom: 2 }}>{confirmationLabel}</Typography>
          <KButton variant="contained" onClick={() => setConfirmation(true)}>
            {t("admin:configuration.rjsf.ui.confirmation.action.label")}
          </KButton>
        </Alert>
      </Container>
    );
  }

  return (
    <Container component={Paper} sx={{ padding: 1 }}>
      <Typography variant="h6">
        {schema.title ? t(schema.title) : t("admin:labels.translation.resource.label")}
      </Typography>
      <ResourceRenderer
        resourceType={resourceType}
        resourceUrl={currentResourceUrl}
        onResourceRemove={handleResourceRemove}
        hideResourceRemoveButton={hideResourceRemoveButton}
      />
      <ExtensionList
        resourceType={resourceType}
        overloadedExtensionKey={uiSchema?.["ui:extensionKey"]}
      />
      <LoadingInformation status={resourceUploadState} />
      <Box
        sx={{
          ...((resourceUploadState === LoadingStatus.LOADING ||
            resourceUploadState === LoadingStatus.LOADED) && { display: "none" }),
          paddingBottom: 0.5,
        }}
      >
        <FilePond
          onaddfilestart={() => setResourceUploadState(LoadingStatus.LOADING)}
          allowMultiple={false}
          name="files"
          maxFileSize={"50MB"}
          labelMaxFileSizeExceeded={t("admin:resource.upload.error.maxFileSizeExceeded") as string}
          labelMaxFileSize={t("admin:resource.upload.error.maxFileSize") as string}
          labelIdle={t("admin:resource.upload.label") as string}
          onaddfile={async (error: FilePondErrorDescription | null, file: FilePondFile) => {
            if (error === null) {
              const resource = await handleAddFile(file);
              if (resource) {
                if (!key) {
                  handleOnChange({ id: resource.id, url: resource.url });
                } else {
                  addNewValueInApiStoreMap(key, resource.url, setApiStore, setDataChange);
                  setCurrentResourceUrl(resource.url);
                }
              }
            } else {
              setResourceUploadState(LoadingStatus.ERROR);
            }
          }}
          onerror={(status, file) => {
            logger.error(
              "Error while loading file ",
              file?.filename,
              " with error code ",
              status.code,
            );
          }}
          credits={false}
        />
      </Box>
    </Container>
  );
};

export default CustomSelectResource;
