import { useEffect } from "react";

import { activeBusinessFilters } from "@keepeek/commons";
import { useRecoilValue, useSetRecoilState } from "recoil";

import { activeBusinessFiltersFromURLSelector } from "../selectors/activeFilters";

export const useActiveBusinessFiltersSync = () => {
  const getActiveBusinessFiltersFromURL = useRecoilValue(activeBusinessFiltersFromURLSelector);
  const setActiveBusinessFilters = useSetRecoilState(activeBusinessFilters);
  useEffect(() => {
    setActiveBusinessFilters(getActiveBusinessFiltersFromURL);
  }, [getActiveBusinessFiltersFromURL, setActiveBusinessFilters]);
};
