import { useEffect } from "react";

import { KpkApiHitSearchType } from "@keepeek/api-client";
import {
  BusinessFilter,
  BusinessFilterNature,
  createFolderBusinessFilter,
  DataViewKey,
  dataViewPage,
  getBusinessFilterFirstValue,
  MediaField,
  selectedElements,
  BusinessFilterExtraParams,
} from "@keepeek/commons";
import { useRouter } from "next/router";
import { useTranslation } from "react-i18next";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import { PagesPath } from "../../../containers/App/utils";
import { basketDetailState } from "../atoms/basketDetail";
import {
  activeBusinessFiltersFromURLSelector,
  activeRawURLFiltersFromURLSelector,
} from "../selectors/activeFilters";
import { useLBF } from "./useLBF";

export type UseActiveFilters = {
  activeFilters: BusinessFilter[];
  rawURLFilters: { lbf: string };
  setActiveFilters: (filters: BusinessFilter[], extraParams?: BusinessFilterExtraParams) => void;
  resetFiltersExceptFolder: () => void;
  isFolderSearch: boolean;
};
/**
 * Update the filters with active filter connector (currently: LBF)
 * Receive the activeFilters at the same time for convenience
 * @author JBO
 */
export const useActiveFilters = (): UseActiveFilters => {
  const router = useRouter();
  const { setLBF } = useLBF();
  const setPage = useSetRecoilState(dataViewPage(DataViewKey.SEARCH));
  const activeFilters = useRecoilValue(activeBusinessFiltersFromURLSelector);
  const rawURLFilters = useRecoilValue(activeRawURLFiltersFromURLSelector);
  const setSelectedElements = useSetRecoilState(selectedElements(DataViewKey.SEARCH));
  const [basketDetail, setBasketDetailState] = useRecoilState(basketDetailState);
  const { t } = useTranslation();

  useEffect(() => {
    if (
      router.pathname !== PagesPath.BASKET_ELEMENT_PAGE &&
      router.pathname !== PagesPath.ELEMENT_PAGE &&
      basketDetail &&
      basketDetail.length !== 0
    ) {
      setBasketDetailState([]);
    }
  }, [basketDetail, router, setBasketDetailState]);

  /**
   * Update active filters trough current active filters connector
   * At the moment, we're using LBF
   * @author JBO
   *
   * @param {BusinessFilter[]} filters
   */
  const setActiveFilters = (filters: BusinessFilter[], extraParams?: BusinessFilterExtraParams) => {
    setLBF(filters, extraParams);
    // reset page 1 on filter change :
    // TODO : setPage has to be move to manage genericity : NO DATAVIEW CONCEPT IN FILTERS
    setPage(1);
    // Reset selected items when change filters
    setSelectedElements([]);
  };

  /**
   * Reset all active filters to none, except the folder filter
   * @author CRO
   */
  const resetFiltersExceptFolder = () => {
    const filterFolder = getBusinessFilterFirstValue(
      activeFilters,
      BusinessFilterNature.ADVANCED,
      MediaField.FOLDER_ID,
    );
    if (filterFolder) {
      setActiveFilters([
        createFolderBusinessFilter(
          filterFolder.id,
          filterFolder.label,
          t("business-filter.advanced.folder.label"),
        ),
      ]);
    } else {
      setActiveFilters([]);
    }
  };

  return {
    activeFilters,
    rawURLFilters,
    setActiveFilters,
    resetFiltersExceptFolder,
    isFolderSearch:
      activeFilters &&
      activeFilters.length === 1 &&
      activeFilters[0].filter &&
      activeFilters[0].filter.type === KpkApiHitSearchType.Folder.toString(),
  };
};
