import {
  BusinessFilterFilter,
  DataViewElementsAuthorizedIcon,
  DataViewElementsButtonsConfiguration,
  DataViewElementsLinksConfiguration,
  DataViewElementsThumbnailSize,
  DataViewFieldsConfiguration,
  ElementIdDisplayRule,
  Field,
  ListViewSize,
  VisualAlert,
  VisualAlertDateTargetMode,
  VisualAlertDateTargetUnit,
  VisualAlertFieldType,
  VisualAlertPosition,
} from "@keepeek/commons";
import { UiSchema } from "@rjsf/utils";
import { JSONSchema7Definition } from "json-schema";

import { Config } from "../../lib/commonPagePropsUtil";
import { getVisualAlertsFieldsForApi } from "../../lib/field-utils";
import { BusinessFilterFilter as BusinessFilterFilterFromJSONSchema } from "../../models/configuration/components/businessFilter";
import {
  Dataview,
  ElementIcon,
  ListViewSize as AdminListViewSize,
} from "../../models/configuration/components/dataview";
import { DownloadManager } from "../../models/configuration/components/downloadManager";
import { ShareManager } from "../../models/configuration/components/shareManager";
import { Template } from "../../models/configuration/definitions/template";
import {
  DateTargetMode,
  DateTargetUnit,
  FieldType,
  Position,
  VisualAlertsSchema,
} from "../../models/configuration/definitions/visualAlertsSchema";
import { Global, Visibility } from "../../models/configuration/global";
import { Search } from "../../models/configuration/pages/search";
import { Inherit, Share } from "../../models/configuration/pages/share";

export type InitConfigApi = { config?: Config; clientIp: string };

export enum KTemplatesTemplatesName {
  Templates = "templates",
  TemplatesLayout = "templatesLayout",
  TemplatesFooter = "templatesFooter",
}

/**
 * Check that a template is present with at least one column and one widget
 */
export function isTemplatesConfiguredWithWidget(templates: Template[] | undefined): boolean {
  if (!templates || templates.length === 0) {
    return false;
  }
  return templates.some(
    (t) => t.columns && t.columns.some((c) => c.widgets && c.widgets.length > 0),
  );
}

export function isPageVisible(config: Global | undefined, pageName: Visibility): boolean {
  if (!config || !pageName) {
    return true;
  }

  if (config && config?.pages && config?.pages?.visibility) {
    const visible = config?.pages?.visibility?.find((page) => page === pageName);
    return visible !== undefined;
  }
  return true;
}

export type FilterJSONSchemaAgainstRuleProps = {
  schemaToFilter: JSONSchema7Definition;
  uiSchema?: UiSchema;
  ruleCallback: (uiSchema: UiSchema) => boolean;
  shouldEvaluateCallback?: (props: FilterJSONSchemaAgainstRuleProps) => boolean;
  checkOnRequired?: boolean;
};

export const filterJSONSchemaAgainstRule = ({
  schemaToFilter,
  uiSchema,
  ruleCallback,
  shouldEvaluateCallback = () => true,
  checkOnRequired = true,
}: FilterJSONSchemaAgainstRuleProps): void => {
  if (
    shouldEvaluateCallback({
      schemaToFilter,
      uiSchema,
      ruleCallback,
      shouldEvaluateCallback,
      checkOnRequired,
    })
  ) {
    Object.keys(schemaToFilter).forEach((key) => {
      if (key === "properties" && schemaToFilter["type"] === "object") {
        Object.keys(schemaToFilter["properties"]).forEach((property) => {
          if (!uiSchema || !(property in uiSchema) || !ruleCallback(uiSchema)) {
            delete schemaToFilter["properties"][property];
          } else {
            filterJSONSchemaAgainstRule({
              schemaToFilter: schemaToFilter["properties"][property],
              uiSchema: uiSchema[property],
              ruleCallback,
              shouldEvaluateCallback,
              checkOnRequired,
            });
          }
        });
      } else if (key === "items" && schemaToFilter["type"] === "array") {
        filterJSONSchemaAgainstRule({
          schemaToFilter: schemaToFilter["items"],
          uiSchema: uiSchema?.["items"],
          ruleCallback,
          shouldEvaluateCallback,
          checkOnRequired,
        });
      } else if (key === "required" && checkOnRequired) {
        // check if required field is still in schema ? if not, clean required field
        schemaToFilter[key] = schemaToFilter[key].filter((field) => {
          for (const property of Object.keys(schemaToFilter["properties"])) {
            if (property === field) {
              return true;
            }
          }
          return false;
        });
      }
    });
  }
};

export function dataViewFieldsConfigurationAdaptor(
  isShare: boolean,
  searchConfig: Search,
  shareConfig: (Share & Search) | undefined,
  downloadManagerConfig: DownloadManager | undefined,
): DataViewFieldsConfiguration {
  const contextualConfig = getContextualSearchOrShareConfiguration(
    isShare,
    searchConfig,
    shareConfig,
  );
  return {
    functionalFields: {
      default: contextualConfig?.functionalFieldsToDisplay?.default,
      metamodelByType: contextualConfig?.functionalFieldsToDisplay?.metamodelType?.map((mmType) => {
        return {
          fields: mmType.fields,
          metamodelType: mmType.metamodelType,
        };
      }),
      metamodelById: contextualConfig?.functionalFieldsToDisplay?.metamodel?.map((mmId) => {
        return {
          fields: mmId.fields,
          metamodelId: mmId.metamodelId,
        };
      }),
    },
    technicalFields: {
      default: contextualConfig?.technicalFieldsToDisplay?.default,
      metamodelByType: contextualConfig?.technicalFieldsToDisplay?.metamodelType?.map((mmType) => {
        return {
          fields: mmType.fields,
          metamodelType: mmType.metamodelType,
        };
      }),
      metamodelById: contextualConfig?.technicalFieldsToDisplay?.metamodel?.map((mmId) => {
        return {
          fields: mmId.fields,
          metamodelId: mmId.metamodelId,
        };
      }),
    },
    listViewFields: {
      default: searchConfig.listViewFieldsToDisplay?.default,
    },
    needAttachmentCounts:
      showAttachmentButton(contextualConfig) ||
      showAttachmentLink(contextualConfig, downloadManagerConfig),
    needMediaLinks: showMediaLink(contextualConfig),
    visualAlertsFields: getVisualAlertsFieldsForApi(contextualConfig?.visualAlerts ?? []),
  };
}

export function dataViewSortDefaultAdaptor(
  isShare: boolean,
  searchConfig: Search,
  shareConfig: (Share & Search) | undefined,
): Field | undefined {
  const contextualConfig = getContextualSearchOrShareConfiguration(
    isShare,
    searchConfig,
    shareConfig,
  );
  const defaultSortConf = contextualConfig?.sort?.fields?.find((f) => f.default);
  if (defaultSortConf) {
    return {
      default: true,
      field: defaultSortConf.field,
      direction: defaultSortConf.direction,
    };
  }
  return undefined;
}

export function businessFiltersConfigurationAdaptor(
  filtersJSONSchema: BusinessFilterFilterFromJSONSchema[],
): BusinessFilterFilter[] {
  return filtersJSONSchema.map<BusinessFilterFilter>((filterJSONSChema) => {
    return {
      filters: filterJSONSChema.filters,
      title: filterJSONSChema.title,
      type: filterJSONSChema.type,
      hideFacetOccurrences: filterJSONSChema.hideFacetOccurrences,
      name: filterJSONSChema.name,
      orderDirectionFacetValues: filterJSONSChema.orderDirectionFacetValues,
      orderTypeFacetValues: filterJSONSChema.orderTypeFacetValues,
    };
  });
}

export function dataViewElementsButtonsConfigurationAdaptor(
  isShare: boolean,
  configSectionPageSearch: Search | undefined,
  configSectionPageShare: Share & Search,
  shareManager: ShareManager | undefined,
): DataViewElementsButtonsConfiguration {
  const contextualConfig = getContextualSearchOrShareConfiguration(
    isShare,
    configSectionPageSearch,
    configSectionPageShare,
  );
  return {
    showAddToBasket: true, //No dedicated configuration for the moment...
    showShare: showShareButton(shareManager),
    showAttachment: showAttachmentButton(contextualConfig),
    showDownload: true, //No Dedicated configuration for the moment...,
  };
}

/**
 * allows to return according to the context (overloaded configuration + current sharing?) the configuration of the dataview page or that of the public sharing page
 * @param isShare we are on a refront session in public sharing mode?
 * @param configSectionPageSearch search configuration page
 * @param configSectionPageShare share configuration page
 */
function getContextualSearchOrShareConfiguration(
  isShare: boolean,
  configSectionPageSearch: Search | undefined,
  configSectionPageShare: (Share & Search) | undefined,
): Search | undefined {
  if (!isShare) {
    return configSectionPageSearch;
  } else {
    return configSectionPageShare?.inherit === Inherit.Inherit
      ? configSectionPageSearch
      : configSectionPageShare;
  }
}

export function dataViewElementsLinksConfigurationAdaptor(
  isShare: boolean,
  configSectionPageSearch: Search | undefined,
  configSectionPageShare: Share & Search,
  downloadManagerConfig: DownloadManager | undefined,
): DataViewElementsLinksConfiguration {
  const contextualConfig = getContextualSearchOrShareConfiguration(
    isShare,
    configSectionPageSearch,
    configSectionPageShare,
  );
  return {
    showAttachment: showAttachmentLink(contextualConfig, downloadManagerConfig),
    showMediaLink: showMediaLink(contextualConfig),
  };
}

export function dataViewElementsAuthorizedIconsConfigurationAdaptor(
  dataviewConf: Dataview | undefined,
): DataViewElementsAuthorizedIcon[] | undefined {
  return dataviewConf?.elementIcons?.map((icon) =>
    checkAndGetEnumValue<ElementIcon, DataViewElementsAuthorizedIcon>(
      icon,
      DataViewElementsAuthorizedIcon,
    ),
  );
}

export function dataViewElementsElementIdDisplayRuleConfigurationAdaptor(
  isShare: boolean,
  searchConfig: Search | undefined,
  shareConfig: (Share & Search) | undefined,
): ElementIdDisplayRule | undefined {
  const contextualConfig = getContextualSearchOrShareConfiguration(
    isShare,
    searchConfig,
    shareConfig,
  );
  return contextualConfig?.elementId;
}

export function dataViewElementsVisualAlertConfigurationAdaptor(
  isShare: boolean,
  searchConfig: Search | undefined,
  shareConfig: (Share & Search) | undefined,
): VisualAlert[] | undefined {
  const contextualConfig = getContextualSearchOrShareConfiguration(
    isShare,
    searchConfig,
    shareConfig,
  );
  return visualAlertsAdaptor(contextualConfig?.visualAlerts);
}

export function dataViewElementsThumbnailSizeConfigurationAdaptor(
  dataviewConf: Dataview | undefined,
): DataViewElementsThumbnailSize | undefined {
  return dataviewConf?.elementImageSize
    ? DataViewElementsThumbnailSize.CONTAIN
    : DataViewElementsThumbnailSize.COVER;
}

export function listViewSizeConfigurationAdaptor(dataviewConf: Dataview | undefined): ListViewSize {
  switch (dataviewConf?.listViewSize) {
    case AdminListViewSize.L: {
      return ListViewSize.L;
    }
    case AdminListViewSize.Xl: {
      return ListViewSize.XL;
    }
    default: {
      return ListViewSize.L;
    }
  }
}

const showMediaLink = (contextualConfig: Search | undefined): boolean => {
  return contextualConfig?.cardLinks?.mediaLinks || false;
};

const showAttachmentLink = (
  contextualConfig: Search | undefined,
  downloadManagerConfig: DownloadManager | undefined,
): boolean => {
  return (
    (contextualConfig?.cardLinks?.attachments && downloadManagerConfig?.enableOtherFormats) || false
  );
};
const showShareButton = (shareManager: ShareManager | undefined): boolean => {
  return shareManager?.unitaryPublicShare?.active || false;
};
const showAttachmentButton = (contextualConfig: Search | undefined): boolean => {
  return contextualConfig?.authorizedActions?.attachments || false;
};

export const visualAlertsAdaptor = (
  visualAlertsBDD: VisualAlertsSchema[] | undefined,
): VisualAlert[] | undefined => {
  return visualAlertsBDD?.map((bddVisualAlert) => {
    return {
      ...bddVisualAlert,
      dateTargetUnit: checkAndGetEnumValue<DateTargetUnit, VisualAlertDateTargetUnit>(
        bddVisualAlert.dateTargetUnit,
        VisualAlertDateTargetUnit,
      ),
      dateTargetMode: checkAndGetEnumValue<DateTargetMode, VisualAlertDateTargetMode>(
        bddVisualAlert.dateTargetMode,
        VisualAlertDateTargetMode,
      ),
      fieldType: checkAndGetEnumValue<FieldType, VisualAlertFieldType>(
        bddVisualAlert.fieldType,
        VisualAlertFieldType,
      ),
      position: checkAndGetEnumValue<Position, VisualAlertPosition>(
        bddVisualAlert.position,
        VisualAlertPosition,
      ),
    };
  });
};

/**
 * makes it possible to check that a value of a source enum is present in a destination enum.
 * The value of the destination enum is returned if found, otherwise an exception is raised
 *
 * @param sourceEnumValue source enum value to found in target enum
 * @param targetEnum target ENUM
 */
function checkAndGetEnumValue<S extends string, T extends string>(
  sourceEnumValue: S,
  targetEnum: Record<string, T>,
): T {
  const foundedValue = Object.values(targetEnum).find(
    (val) => val === (sourceEnumValue as unknown as T),
  );
  if (!foundedValue) {
    throw new Error(
      `Value not found in target enum: "${sourceEnumValue}" => "${Object.values(targetEnum)}" `,
    );
  }
  return foundedValue;
}
