import { useEffect } from "react";

import { setActiveBusinessFiltersState } from "@keepeek/commons";
import { useSetRecoilState } from "recoil";

import { useActiveFilters } from "./useActiveFilters";

export const useSetActiveBusinessFiltersSync = () => {
  const { setActiveFilters } = useActiveFilters();
  const setSetActiveBusinessFilters = useSetRecoilState(setActiveBusinessFiltersState);
  useEffect(() => {
    // Set the atom that contains a function to update activeFilters
    setSetActiveBusinessFilters(() => setActiveFilters);
  }, [setActiveFilters, setSetActiveBusinessFilters]);
};
