/**
 * Defines administration menu content.
 * It's used to display menu and to display sections in the right order in import menu.
 */
export type AdminMenuItem = AdminMenuWithoutChilds | AdminMenuWithChilds;

export type AdminMenuWithoutChilds = {
  hasChild: false;
  /**
   * Menu icon key.
   */
  iconKey: string;
} & AdminMenuHref;

export type AdminMenuWithChilds = {
  hasChild: true;
  /**
   * React menu key.
   */
  key: string;
  /**
   * Menu icon key.
   */
  iconKey: string;
  /**
   * Section label key.
   */
  labelKey: string;
  /**
   * Menu childs
   */
  childs: AdminMenuHref[];
};

export type AdminMenuHref = {
  /**
   * React menu key.
   */
  key: string;
  /**
   * Configuration href, href match configuration section in database.
   */
  href: string;
  /**
   * Section label key.
   */
  labelKey: string;
  /**
   * Allow copying configuration from another section.
   * This will display a button to copy configuration.
   */
  copyConfigSection?: string;
};

export enum CONFIGURATION_SECTION_PATH {
  // GENERAL
  GLOBAL = "global",
  THEME = "theme",
  ICONS = "icons",
  LABELS = "labels",
  USERS = "users",
  // LAYOUT
  LAYOUT_DEFAULT = "layout/default",
  LAYOUT_LANDING = "layout/landing",
  PAGES_LANDING = "pages/landing",
  // PAGES
  PAGES_HOME = "pages/home",
  PAGES_SEARCH = "pages/search",
  PAGES_ELEMENT = "pages/element",
  PAGES_SHARE = "pages/share",
  PAGES_SHARE_ELEMENT = "pages/shareElement",
  PAGES_BASKETS = "pages/baskets",
  PAGES_BASKET_ELEMENT = "pages/basketElement",
  // WIDGETS
  COMPONENTS_MOSAIC = "components/mosaic",
  COMPONENTS_SEARCH_WIDGET = "components/searchWidget",
  COMPONENTS_FOOTER_WIDGET = "components/footerWidget",
  COMPONENTS_TABS_WIDGET = "components/tabsWidget",
  COMPONENTS_K_IMAGE_WIDGET = "components/kImageWidget",
  COMPONENTS_EDITO_WIDGET = "components/editoWidget",
  COMPONENTS_K_HEADER_WIDGET = "components/kHeaderWidget",
  COMPONENTS_ASSOCIATIONS_WIDGET = "components/associationsWidget",
  COMPONENTS_SPACER_WIDGET = "components/spacerWidget",
  COMPONENTS_CUSTOM_WIDGET = "components/customWidget",
  COMPONENTS_K_VIDEO_WIDGET = "components/kVideoWidget",
  COMPONENTS_SHEET_WIDGET = "components/sheetWidget",
  COMPONENTS_ATTACHMENTS_WIDGET = "components/attachmentsWidget",
  COMPONENTS_NEWS_WIDGET = "components/kNewsWidget",
  COMPONENTS_SECTION_TITLE_WIDGET = "components/kSectionTitleWidget",
  COMPONENTS_MENU_WIDGET = "components/kMenuWidget",
  COMPONENTS_REPORT_WIDGET = "components/kReportWidget",
  COMPONENTS_BASKETS_WIDGET = "components/basketsWidget",
  // COMPONENTS
  COMPONENTS_BUSINESS_FILTER = "components/businessFilter",
  COMPONENTS_BREADCRUMB = "components/breadcrumb",
  COMPONENTS_DATAVIEW = "components/dataview",
  COMPONENTS_VIEWER = "components/viewer",
  COMPONENTS_AUTOCOMPLETE = "components/autocomplete",
  COMPONENTS_BASKET = "components/basket",
  COMPONENTS_DOWNLOAD_MANAGER = "components/downloadManager",
  COMPONENTS_FOOTER = "components/footer",
  COMPONENTS_FOLDER_BROWSE = "components/folderBrowse",
  COMPONENTS_IMPORT = "components/import",
  COMPONENTS_FRONT_EDIT = "components/frontEdit",
  COMPONENTS_SHARE_MANAGER = "components/shareManager",
  // IMPORT/EXPORT
  EXPORT = "export",
  IMPORT = "import",
  TEMPLATES = "templates",
  ENVIRONMENT = "environment",
  LOGS = "logs",
  // RESOURCES
  RESOURCES = "resources",
  // OVERRIDE
  OVERRIDE = "override",
  WAF = "waf",
}

export enum WidgetAdminLabelKeys {
  Header = "admin:layout.menu.widgets.kHeaderWidget.label",
  Mosaic = "admin:layout.menu.widgets.mosaic.label",
  Search = "admin:layout.menu.widgets.searchWidget.label",
  Footer = "admin:layout.menu.widgets.footer-widget.label",
  Tabs = "admin:layout.menu.widgets.tabsWidget.label",
  Image = "admin:layout.menu.widgets.kImageWidget.label",
  Video = "admin:layout.menu.widgets.video.label",
  Edito = "admin:layout.menu.widgets.editoWidget.label",
  Associations = "admin:layout.menu.widgets.associationsWidget.label",
  Spacer = "admin:layout.menu.widgets.spacerWidget.label",
  Custom = "admin:layout.menu.widgets.customWidget.label",
  Sheet = "admin:layout.menu.widgets.sheetWidget.label",
  Attachments = "admin:layout.menu.widgets.attachmentsWidget.label",
  News = "admin:layout.menu.widgets.kNewsWidget.label",
  SectionTitle = "admin:layout.menu.widgets.kSectionTitleWidget.label",
  Menu = "admin:layout.menu.widgets.kMenuWidget.label",
  Report = "admin:layout.menu.widgets.kReportWidget.label",
  Baskets = "admin:layout.menu.widgets.basketsWidget.label",
}

export const adminMenu: Array<AdminMenuItem> = [
  {
    hasChild: false,
    key: CONFIGURATION_SECTION_PATH.GLOBAL,
    href: CONFIGURATION_SECTION_PATH.GLOBAL,
    iconKey: "settings",
    labelKey: "admin:layout.menu.global.label",
  },
  {
    hasChild: false,
    key: CONFIGURATION_SECTION_PATH.THEME,
    href: CONFIGURATION_SECTION_PATH.THEME,
    iconKey: "palette",
    labelKey: "admin:layout.menu.theme.label",
  },
  {
    hasChild: false,
    key: CONFIGURATION_SECTION_PATH.ICONS,
    href: CONFIGURATION_SECTION_PATH.ICONS,
    iconKey: "burst_mode",
    labelKey: "admin:layout.menu.icons.label",
  },
  {
    hasChild: false,
    key: CONFIGURATION_SECTION_PATH.LABELS,
    href: CONFIGURATION_SECTION_PATH.LABELS,
    iconKey: "translate",
    labelKey: "admin:layout.menu.labels.label",
  },
  {
    hasChild: false,
    key: CONFIGURATION_SECTION_PATH.USERS,
    href: CONFIGURATION_SECTION_PATH.USERS,
    iconKey: "people",
    labelKey: "admin:layout.menu.users.label",
  },
  {
    hasChild: true,
    key: "layout",
    iconKey: "web",
    labelKey: "admin:layout.menu.layout.label",
    childs: [
      {
        key: "layout-default",
        href: CONFIGURATION_SECTION_PATH.LAYOUT_DEFAULT,
        labelKey: "admin:layout.menu.layout.default.label",
      },
      {
        key: "layout-landing",
        href: CONFIGURATION_SECTION_PATH.LAYOUT_LANDING,
        labelKey: "admin:layout.menu.layout.landing.label",
      },
    ],
  },
  {
    hasChild: true,
    key: "pages",
    iconKey: "view_quilt",
    labelKey: "admin:layout.menu.pages.label",
    childs: [
      {
        key: "pages-landing",
        href: CONFIGURATION_SECTION_PATH.PAGES_LANDING,
        labelKey: "admin:layout.menu.pages.landing.label",
      },
      {
        key: "pages-home",
        href: CONFIGURATION_SECTION_PATH.PAGES_HOME,
        labelKey: "admin:layout.menu.pages.home.label",
      },
      {
        key: "pages-search",
        href: CONFIGURATION_SECTION_PATH.PAGES_SEARCH,
        labelKey: "admin:layout.menu.pages.search.label",
      },
      {
        key: "pages-element",
        href: CONFIGURATION_SECTION_PATH.PAGES_ELEMENT,
        labelKey: "admin:layout.menu.pages.element.label",
      },
      {
        key: "pages-share",
        href: CONFIGURATION_SECTION_PATH.PAGES_SHARE,
        labelKey: "admin:layout.menu.pages.share.label",
        copyConfigSection: CONFIGURATION_SECTION_PATH.PAGES_SEARCH,
      },
      {
        key: "pages-share-element",
        href: CONFIGURATION_SECTION_PATH.PAGES_SHARE_ELEMENT,
        labelKey: "admin:layout.menu.pages.shareElement.label",
        copyConfigSection: CONFIGURATION_SECTION_PATH.PAGES_ELEMENT,
      },
      {
        key: "pages-baskets-element",
        href: CONFIGURATION_SECTION_PATH.PAGES_BASKETS,
        labelKey: "admin:layout.menu.pages.baskets.label",
      },
      {
        key: "pages-basket-element",
        href: CONFIGURATION_SECTION_PATH.PAGES_BASKET_ELEMENT,
        labelKey: "admin:layout.menu.pages.basketElement.label",
      },
    ],
  },
  {
    hasChild: true,
    key: "widgets",
    iconKey: "widgets",
    labelKey: "admin:layout.menu.widgets.label",
    childs: [
      {
        key: "widgets-mosaic",
        href: CONFIGURATION_SECTION_PATH.COMPONENTS_MOSAIC,
        labelKey: WidgetAdminLabelKeys.Mosaic,
      },
      {
        key: "widgets-searchWidget",
        href: CONFIGURATION_SECTION_PATH.COMPONENTS_SEARCH_WIDGET,
        labelKey: WidgetAdminLabelKeys.Search,
      },
      {
        key: "widgets-footerWidget",
        href: CONFIGURATION_SECTION_PATH.COMPONENTS_FOOTER_WIDGET,
        labelKey: WidgetAdminLabelKeys.Footer,
      },
      {
        key: "widgets-tabsWidget",
        href: CONFIGURATION_SECTION_PATH.COMPONENTS_TABS_WIDGET,
        labelKey: WidgetAdminLabelKeys.Tabs,
      },
      {
        key: "widgets-kImageWidget",
        href: CONFIGURATION_SECTION_PATH.COMPONENTS_K_IMAGE_WIDGET,
        labelKey: WidgetAdminLabelKeys.Image,
      },
      {
        key: "widgets-editoWidget",
        href: CONFIGURATION_SECTION_PATH.COMPONENTS_EDITO_WIDGET,
        labelKey: WidgetAdminLabelKeys.Edito,
      },
      {
        key: "widgets-kHeaderWidget",
        href: CONFIGURATION_SECTION_PATH.COMPONENTS_K_HEADER_WIDGET,
        labelKey: WidgetAdminLabelKeys.Header,
      },
      {
        key: "widgets-associationsWidget",
        href: CONFIGURATION_SECTION_PATH.COMPONENTS_ASSOCIATIONS_WIDGET,
        labelKey: WidgetAdminLabelKeys.Associations,
      },
      {
        key: "widgets-spacerWidget",
        href: CONFIGURATION_SECTION_PATH.COMPONENTS_SPACER_WIDGET,
        labelKey: WidgetAdminLabelKeys.Spacer,
      },
      {
        key: "widgets-customWidget",
        href: CONFIGURATION_SECTION_PATH.COMPONENTS_CUSTOM_WIDGET,
        labelKey: WidgetAdminLabelKeys.Custom,
      },
      {
        key: "widgets-kVideoWidget",
        href: CONFIGURATION_SECTION_PATH.COMPONENTS_K_VIDEO_WIDGET,
        labelKey: WidgetAdminLabelKeys.Video,
      },
      {
        key: "widgets-kNewsWidget",
        href: CONFIGURATION_SECTION_PATH.COMPONENTS_NEWS_WIDGET,
        labelKey: WidgetAdminLabelKeys.News,
      },
      {
        key: "widgets-kSectionTitleWidget",
        href: CONFIGURATION_SECTION_PATH.COMPONENTS_SECTION_TITLE_WIDGET,
        labelKey: WidgetAdminLabelKeys.SectionTitle,
      },
      {
        key: "widgets-kMenuWidget",
        href: CONFIGURATION_SECTION_PATH.COMPONENTS_MENU_WIDGET,
        labelKey: WidgetAdminLabelKeys.Menu,
      },
      {
        key: "widgets-kReportWidget",
        href: CONFIGURATION_SECTION_PATH.COMPONENTS_REPORT_WIDGET,
        labelKey: WidgetAdminLabelKeys.Report,
      },
      {
        key: "widgets-basketsWidget",
        href: CONFIGURATION_SECTION_PATH.COMPONENTS_BASKETS_WIDGET,
        labelKey: WidgetAdminLabelKeys.Baskets,
      },
    ],
  },
  {
    hasChild: true,
    key: "components",
    iconKey: "code",
    labelKey: "admin:layout.menu.components.label",
    childs: [
      {
        key: "components-businessFilter",
        href: CONFIGURATION_SECTION_PATH.COMPONENTS_BUSINESS_FILTER,
        labelKey: "admin:layout.menu.components.businessFilter.label",
      },
      {
        key: "components-breadcrumb",
        href: CONFIGURATION_SECTION_PATH.COMPONENTS_BREADCRUMB,
        labelKey: "admin:layout.menu.components.breadcrumb.label",
      },
      {
        key: "components-dataview",
        href: CONFIGURATION_SECTION_PATH.COMPONENTS_DATAVIEW,
        labelKey: "admin:layout.menu.components.dataview.label",
      },
      {
        key: "components-viewer",
        href: CONFIGURATION_SECTION_PATH.COMPONENTS_VIEWER,
        labelKey: "admin:layout.menu.components.viewer.label",
      },
      {
        key: "components-autocomplete",
        href: CONFIGURATION_SECTION_PATH.COMPONENTS_AUTOCOMPLETE,
        labelKey: "admin:layout.menu.components.autocomplete.label",
      },
      {
        key: "components-basket",
        href: CONFIGURATION_SECTION_PATH.COMPONENTS_BASKET,
        labelKey: "admin:layout.menu.components.basket.label",
      },
      {
        key: "components-downloadManager",
        href: CONFIGURATION_SECTION_PATH.COMPONENTS_DOWNLOAD_MANAGER,
        labelKey: "admin:layout.menu.components.downloadManager.label",
      },
      {
        key: "components-shareManager",
        href: CONFIGURATION_SECTION_PATH.COMPONENTS_SHARE_MANAGER,
        labelKey: "admin:layout.menu.components.shareManager.label",
      },
      {
        key: "components-footer",
        href: CONFIGURATION_SECTION_PATH.COMPONENTS_FOOTER,
        labelKey: "admin:layout.menu.components.footerWidget.label",
      },
      {
        key: "components-folderBrowse",
        href: CONFIGURATION_SECTION_PATH.COMPONENTS_FOLDER_BROWSE,
        labelKey: "admin:layout.menu.components.folderBrowse.label",
      },
      {
        key: "components-import",
        href: CONFIGURATION_SECTION_PATH.COMPONENTS_IMPORT,
        labelKey: "admin:layout.menu.components.import.label",
      },
      {
        key: "components-frontEdit",
        href: CONFIGURATION_SECTION_PATH.COMPONENTS_FRONT_EDIT,
        labelKey: "admin:layout.menu.components.frontEdit.label",
      },
    ],
  },
  {
    hasChild: true,
    key: "configuration",
    iconKey: "import_export",
    labelKey: "admin:layout.menu.configuration.label",
    childs: [
      {
        key: "configuration-export",
        href: CONFIGURATION_SECTION_PATH.EXPORT,
        labelKey: "admin:layout.menu.configuration.export.label",
      },
      {
        key: "configuration-import",
        href: CONFIGURATION_SECTION_PATH.IMPORT,
        labelKey: "admin:layout.menu.configuration.import.label",
      },
      {
        key: "configuration-templates",
        href: CONFIGURATION_SECTION_PATH.TEMPLATES,
        labelKey: "admin:layout.menu.configuration.templates.label",
      },
      {
        key: "configuration-environment",
        href: CONFIGURATION_SECTION_PATH.ENVIRONMENT,
        labelKey: "admin:layout.menu.configuration.environment.label",
      },
      {
        key: "configuration-logs",
        href: CONFIGURATION_SECTION_PATH.LOGS,
        labelKey: "admin:layout.menu.configuration.logs.label",
      },
    ],
  },
  {
    hasChild: false,
    key: CONFIGURATION_SECTION_PATH.RESOURCES,
    href: CONFIGURATION_SECTION_PATH.RESOURCES,
    labelKey: "admin:layout.menu.resource.label",
    iconKey: "backup",
  },
  {
    key: CONFIGURATION_SECTION_PATH.OVERRIDE,
    hasChild: false,
    iconKey: "library_add",
    labelKey: "admin:layout.menu.override.label",
    href: CONFIGURATION_SECTION_PATH.OVERRIDE,
  },
  {
    key: CONFIGURATION_SECTION_PATH.WAF,
    hasChild: false,
    iconKey: "security",
    labelKey: "admin:layout.menu.waf.label",
    href: CONFIGURATION_SECTION_PATH.WAF,
  },
];
