import { useEffect } from "react";

import { useRouter } from "next/router";
import { useTranslation } from "react-i18next";

import { getInstance, I18NEXT_SESSION_STORAGE_PREFIX_CACHE } from "./i18next/i18next-instance";

export default function useI18nLanguageSwitch() {
  const { locale: currentLocale, defaultLocale } = useRouter();

  const locale: string = currentLocale || defaultLocale || "";

  useEffect(() => {
    getInstance(locale);
  }, [locale]);

  return locale;
}

export const useLocalStorageCache = (): {
  clear: () => Promise<void>;
} => {
  const { i18n } = useTranslation();
  const clear = async () => {
    Object.keys(sessionStorage)
      .filter((x) => x.startsWith(I18NEXT_SESSION_STORAGE_PREFIX_CACHE))
      .forEach((x) => sessionStorage.removeItem(x));
    await i18n.reloadResources();
  };
  return {
    clear,
  };
};
